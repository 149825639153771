@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/custom.scss';

// //Import Light mode
@import './assets/scss/style.scss';

//Import Dark RTL mode
// @import './assets/scss/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/style-dark.scss';

// .BrainhubCarousel__customArrows.BrainhubCarousel__customArrows.BrainhubCarousel__customArrows {
//   position: absolute;
//   z-index: 1000;
//   top: 50%;
//   left: 0px;
//   background: transprent;
// }

// .BrainhubCarousel__customArrows.BrainhubCarousel__customArrows.BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
//   right: 0px;
//   left: auto;
// }


.behinder {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 5000;
    right: 0px;
    background: rgba(0,0,0,0.3)
}

.modcontent .modal-content {
    position: absolute;
    top: 0px;
    bottom: 0px;
}

.buy-menu-btn.buysmall {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.modal-video-movie-wrap {
    padding-bottom: 140% !important;
}

.list-indent {
    text-indent: -20px;
    padding-left: 20px;
  }

.subtit {
    color: #2185D0;
    padding-top: 15px;
}

.lineit {
    font-size: 18px;
}

.kriklass {
    width: 80%;
    text-align: center;
    position: relative;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 500px){
.kriklass {
    width: 80%
}
}

.kripic {
    object-fit: contain;
    position: absolute;
    top: 0px;
    border: 1px solid lightgrey;
    left: 50%;
    transform: translateX(-50%)
}

.xvideo {
    transition: all 0.3s;
    width: 74%;
    transform: rotateX(8deg) rotateY(-14deg) rotateZ(9deg);
    transition: all 0.3s;
    max-height: 500px;
}

.xvideoright {
    transition: all 0.3s;
    width: 74%;
    transform: rotateX(8deg) rotateY(14deg) rotateZ(-9deg);
    transition: all 0.3s;
    max-height: 500px;
}


@media (max-width: 768px){
    .xvideoright {
        transition: all 0.3s;
        width: 60%;
        transform: rotateX(8deg) rotateY(-14deg) rotateZ(9deg);
        transition: all 0.3s;
        max-height: 400px;
    }
    .xvideo {
        transition: all 0.3s;
        width: 60%;
        transform: rotateX(8deg) rotateY(-14deg) rotateZ(9deg);
        transition: all 0.3s;
        max-height: 400px;
    }
}

.xvideo:hover, .xvideoright:hover {
    transform: none;
    
}

@media (max-width: 768px) {
    .centerit {
    text-align: center;
    }
}